import React from 'react';
import { styled } from '@mui/material/styles';

const MetaWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0 16px 16px 16px',
  gap: 'var(--card-spacing-2)',
}));

const MetaData = styled('p')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--card-spacing-2)',
  margin: 0,
}));

const MetaText = styled('p')(() => ({
  padding: '6px',
  fontSize: '0.75rem',
  lineHeight: '0.75rem',
  borderRadius: 'var(--card-radius-1)',
  textDecoration: 'none',
}));

// Define different styles for different meta types
const styledMetaMap = {
  category: styled(MetaText)(() => ({
    color: 'var(--dark-blue)',
    backgroundColor: 'var(--light-blue)',
    '&:hover': {
      backgroundColor: 'var(--blue)',
    },
  })),
  type: styled(MetaText)(() => ({
    color: 'var(--dark-green)',
    backgroundColor: 'var(--light-green)',
    '&:hover': {
      backgroundColor: 'var(--green)',
    },
  })),
  company: styled(MetaText)(() => ({
    color: 'var(--dark-purple)',
    backgroundColor: 'var(--light-purple)',
    '&:hover': {
      backgroundColor: 'var(--purple)',
    },
  })),
  platform: styled(MetaText)(() => ({
    color: 'var(--dark-grey)',
    backgroundColor: 'var(--light-primary-green) !important',
    '&:hover': {
      backgroundColor: 'var(--dark-primary-green) !important',
    },
  })),
};

const CardMetaData = ({ metaData = [], className }) => {
  return (
    <MetaWrapper className={className}>
      {metaData.map((item, index) => {
        const StyledMeta = styledMetaMap[item.type];
        return (
          <MetaData key={index}>
            <StyledMeta>{item.name}</StyledMeta>
          </MetaData>
        );
      })}
    </MetaWrapper>
  );
};

export default CardMetaData;
